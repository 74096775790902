
import { defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { toRaw } from '@vue/reactivity';

import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import yupLocaleTr from 'yup-locale-tr';

import Swal from "sweetalert2/dist/sweetalert2.js";
import { Integration } from "@/dtos/Integration";
import { IntegrationType } from "@/dtos/IntegrationType";
import { TrendyolCredentials } from "@/dtos/Integration";

export default defineComponent({
  name: "create-update-integration",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    selectedIntegration: Object
  },
  emits:["saved"],

  setup(props,ctx) {
    Yup.setLocale(yupLocaleTr);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const prpInt = props.selectedIntegration as Integration;
    const apiData = ref<Integration>(prpInt);
  
    const store = useStore();
    const debounce = (func, wait) => {
      let timeout;

      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    };

    const deepCopy = (obj: any) => {
      if (obj === null || typeof obj !== 'object') {
        return obj;
      }

      const copy = Array.isArray(obj) ? [] : {};

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          copy[key] = deepCopy(obj[key]);
        }
      }

      return copy;
    }
    const changeIntegrationType = (type: number) => {
      apiData.value.integrationType = type;
    };
    const range = (end: number) => {
      return Array.from({ length: end }, (v, k) => k);
    };
    const updateBranches = () => {
      switch (apiData.value.integrationType) {
        case IntegrationType.YemekSepeti:
          store
            .dispatch(Actions.YEMEKSEPETI_CHECK, {
              username: apiData.value.credentials?.username,
              password: apiData.value.credentials?.password,
            })
            .then((r) => {
              if (r.status == 201) {
                apiData.value.branches = r.data;
              } else {
                Swal.fire({
                  title: "Hata",
                  text: "Yemeksepeti kullanıcı adı ve şifre hatalı",
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            });

          break;
        case IntegrationType.Getir:
          store.dispatch(Actions.GETIR_CHECK, {
            username: apiData.value.credentials?.username,
            password: apiData.value.credentials?.password,
          }).then((r) => {
            if (r.status == 201) {
              apiData.value.branches = r.data;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Getir kullanıcı adı ve şifre hatalı (Şifre değiştirme zamanınız gelmiş olabilir, getir panelinde çıkış yapıp giriş yapın)",
                icon: "error",
                confirmButtonText: "Tamam"
              });
            }
          });
          break;
        case IntegrationType.MigrosYemek:
          store.dispatch(Actions.MIGROS_CHECK, {
            username: apiData.value.credentials?.username,
            password: apiData.value.credentials?.password,
          }).then((r) => {
            if (r.status == 201) {
              apiData.value.branches = r.data;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Migros kullanıcı adı ve şifre hatalı (Şifre değiştirme zamanınız gelmiş olabilir, migros panelinde çıkış yapıp giriş yapın)",
                icon: "error",
                confirmButtonText: "Tamam"
              });
            }
          });
          break;
        case IntegrationType.PaketServisi:
          store.dispatch(Actions.PAKETSERVISI_CHECK, {
            username: apiData.value.credentials?.username,
            password: apiData.value.credentials?.password,
          }).then((r) => {
            if (r.status == 201) {
              apiData.value.branches = r.data;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Paket Servisi kullanıcı adı ve şifre hatalı",
                icon: "error",
                confirmButtonText: "Tamam"
              });
            }
          });
          break;
        case IntegrationType.Trendyol: {
          const trendyolCreds = apiData.value.credentials as TrendyolCredentials;

          store.dispatch(Actions.TRENDYOL_CHECK, {
            supplierId: trendyolCreds.supplierId,
            apiKey: trendyolCreds.apiKey,
            apiSecret: trendyolCreds.apiSecret,
          }).then((r) => {
            if (r.status == 201) {
              apiData.value.branches = r.data;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Trendyol bilgileri hatalı",
                icon: "error",
                confirmButtonText: "Tamam"
              });
            }
          });
        }
          break;
      }
    };
    const debouncedUpdateBranches = debounce(updateBranches, 1000); // 500 ms bekleme süresi

    watch(() => props.selectedIntegration, (newValue, oldValue) => {
      // newValue'nin düz nesne versiyonunu al
      const rawValue = toRaw(newValue as Integration);
      // rawValue'nin kopyasını oluşturarak orijinal nesnenin değiştirilmemesini sağla
      apiData.value = deepCopy(rawValue);
      updateBranches();
    });
    watch(
      () => [apiData?.value?.credentials?.username, apiData?.value?.credentials?.password,(apiData?.value?.credentials as TrendyolCredentials)?.supplierId,(apiData?.value?.credentials as TrendyolCredentials)?.apiKey,(apiData?.value?.credentials as TrendyolCredentials)?.apiSecret ],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          debouncedUpdateBranches();
        }
      },
      { deep: true }
    );

    const validationSchema = Yup.lazy(values => {
      let schema = Yup.object().shape({
        branch: Yup.string().required().label("Şube"),
      });

      if (apiData.value.integrationType === 0) {
        schema = schema.concat(
          Yup.object().shape({
            menu: Yup.string().required().label("Menü"),
          })
        );
      } else if (apiData.value.integrationType != IntegrationType.Trendyol) {
        schema = schema.concat(
          Yup.object().shape({
            userName: Yup.string().required().label("Kullanıcı Adı"),
            password: Yup.string().required().label("Parola"),
          })
        )
      } else if (apiData.value.integrationType == IntegrationType.Trendyol) {
        schema = schema.concat(
          Yup.object().shape({
            apiKey: Yup.string().required().label("Api Key"),
            apiSecret: Yup.string().required().label("Api Secret"),
            supplierId: Yup.string().required().label("Satıcı Id"),
          })
        )
      }

      return schema;
    });

    const submit = () => {

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      if(apiData.value.integrationType == IntegrationType.MigrosYemek && apiData.value.credentials){
        apiData.value.credentials.selectedMenuId = apiData.value.branches.find(p=>p.Id == apiData.value.credentials?.selectedBranch).Tag.toString();
      }
      // if apiData.value has isNew property than set it to null
      if(Object.prototype.hasOwnProperty.call(apiData.value,"isNew"))
        delete apiData.value.isNew;
      store.dispatch(Actions.UPDATE_INTEGRATION, apiData.value).then((r) => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }
        if (r&&r.length >0) {
          ctx.emit("saved");
          Swal.fire({
            text: "Entegrasyon başarı ile güncellendi!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Tamam!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            hideModal(createAPIKeyModalRef.value);
          });
        } else {
          Swal.fire({
            title: "Hata",
            text: "Kayıt yapılırken sorun çıktı",
            icon: "error",
            confirmButtonText: "Tamam"
          });
        }
      });

    };

    return {
      apiData,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createAPIKeyModalRef,
      IntegrationType,
      changeIntegrationType,
      range
    };
  },
});
